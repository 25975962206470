import { Component, Injector, OnDestroy, OnInit, inject } from '@angular/core';
import { BaseModalComponent } from '@util/services/util-modal-types';
import { UtilOmnipresentFormGroupError, UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { filter, map, distinctUntilChanged, catchError, delay, tap, finalize } from 'rxjs/operators';
import { CountryMailAddress, KurzMailService } from 'src/app/custom/services/kurz-mail.service';
import { CookieBannerAndGtmService } from 'src/app/custom/services/cookie-banner-and-gtm.service';

@Component({
  selector: 'app-kurz-request-access-modal',
  templateUrl: './kurz-request-access-modal.component.html',
  styleUrls: ['./kurz-request-access-modal.component.scss']
})
export class KurzRequestAccessModalComponent extends BaseModalComponent<void, void> implements OnInit, OnDestroy {

  private utilOmnipresentFormGroupService = inject(UtilOmnipresentFormGroupService);
  private kurzMailService = inject(KurzMailService);
  private usercentricsAndGtmService = inject(CookieBannerAndGtmService);

  step$ = new BehaviorSubject<1 | 2>(1);

  sub: Subscription;
  name: string;
  email: string;
  companyName: string;
  street: string;
  houseNumber: string;
  postcode: string;
  city: string;

  countryMailAddress: CountryMailAddress;

  loading$ = new BehaviorSubject<boolean>(false);

  get formGroupName(): string {
    return 'requestAccessModal';
  }

  get formGroupErrors$(): Observable<UtilOmnipresentFormGroupError> {
    return this.utilOmnipresentFormGroupService.getFormGroupError$(this.formGroupName);
  }

  get invalid$(): Observable<boolean> {
    return this.formGroupErrors$.pipe(
      filter(_ => !!_),
      map(errors => !!(errors.invalid)),
      distinctUntilChanged(),
      // avoiding ExpressionChangedAfterItHasBeenCheckedError
      delay(0)
    );
  }

  invalid = false;


  ngOnInit(): void {
    this.sub = this.invalid$.subscribe(inv => {
      // avoiding ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.invalid = inv);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  accept() {
    this.step$.next(2);
  }

  send(e: UIEvent) {

    const button = e.target as HTMLButtonElement;
    const buttonLabel = button?.innerText;

    if (buttonLabel) {
      this.loading$.next(true);
      this.kurzMailService.sendMail({
        mailType: 'REQUEST_ACCESS',
        name: this.name,
        email: this.email,
        countryMail: this.countryMailAddress?.code,
        companyName: this.companyName,
        streetAndNumber: `${this.street} ${this.houseNumber}`,
        postcodeAndCity: `${this.postcode} ${this.city}`,
      }).pipe(
        catchError((err, caught) => {
          return throwError(err);
        }),
        tap(() => {
          this.usercentricsAndGtmService.addSignUpEvent(buttonLabel);
        }),
        finalize(() => {
          this.loading$.next(false);
        })
      ).subscribe(
        res => {
          console.log('request access result', res);
          this.closeModal();
        }
      );
    } else {
      console.error('Target of UI Event was not detected as expected');
    }
  }

  userSelectsCountryMailAddress(item: CountryMailAddress) {
    this.countryMailAddress = item;
  }

}
