<app-kurz-modal-wrapper [options]="{ref: this}">

  <ng-template modalSlot="header">

    @if ((step$ | async) === 1) {
      <h1>{{ 'customLogin.requestAccessModal.step1.title' | cxTranslate }}</h1>
    }

    @if ((step$ | async) === 2) {
      <h1>{{ 'customLogin.requestAccessModal.step2.title' | cxTranslate }}</h1>
    }

  </ng-template>

  @if ((step$ | async) === 2) {
    <ng-template modalSlot="kurzContext">
      <div class="modal-context">
        {{ 'customLogin.requestAccessModal.step2.context' | cxTranslate }}
      </div>
    </ng-template>
  }

  <ng-template modalSlot="content">

    @if ((step$ | async) === 1) {
      <div class="modal-step1">

        <div class="content-part">
          @if ('customLogin.requestAccessModal.step1.p1' | cxTranslate; as tr) {
            <div>{{ tr }}</div>
            <br/>
          }
          @if ('customLogin.requestAccessModal.step1.p2' | cxTranslate; as tr) {
            <div>{{ tr }}</div>
          }
          <app-html-deserializer class="content-part" [string]="'customLogin.requestAccessModal.step1.countryList' | cxTranslate"></app-html-deserializer>
        </div>


        @if ('customLogin.requestAccessModal.step1.p3' | cxTranslate; as tr) {
          <div class="content-part">
            <div>{{ tr }}</div>
          </div>
        }

        <div class="content-part">
          <app-button
            [label]="'customLogin.requestAccessModal.step1.acceptLabel' | cxTranslate"
            [colorTheme]="['PRIMARY', 'BASE', {}]"
            size="MEDIUM"
            (activateElement)="accept()"
          ></app-button>
        </div>

      </div>
    }

    @if ((step$ | async) === 2) {

      <div class="modal-form">

        <app-input
          [label]="'customLogin.requestAccessModal.step2.nameLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.namePlaceholder' | cxTranslate"
          [(value)]="name"
          ariaDescription="input sur- and lastname"
          name="name"
          autocomplele="name"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.emailLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.emailPlaceholder' | cxTranslate"
          [(value)]="email"
          ariaDescription="input valid eMail address please"
          name="email"
          autocomplele="email"
          appInputRequiredValidator
          appInputEmailValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.companyNameLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.companyNameLabel' | cxTranslate"
          [(value)]="companyName"
          ariaDescription="input company name"
          name="company"
          autocomplele="company"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.streetLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.streetPlaceholder' | cxTranslate"
          [(value)]="street"
          ariaDescription="input street"
          name="street"
          autocomplele="street"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.houseNumberLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.houseNumberPlaceholder' | cxTranslate"
          [(value)]="houseNumber"
          ariaDescription="input house number"
          name="houseNumber"
          autocomplele="houseNumber"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.postcodeLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.postcodePlaceholder' | cxTranslate"
          [(value)]="postcode"
          ariaDescription="input valid postcode"
          name="postcode"
          autocomplele="postcode"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-input
          [label]="'customLogin.requestAccessModal.step2.cityLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.cityPlaceholder' | cxTranslate"
          [(value)]="city"
          ariaDescription="input valid city"
          name="city"
          autocomplele="city"
          appInputRequiredValidator
          [formGroupName]="formGroupName"
        ></app-input>

        <app-kurz-country-autocomplete
          (select)="userSelectsCountryMailAddress($event)"
          [formGroupName]="formGroupName"
          required
        ></app-kurz-country-autocomplete>

        <!-- honeypot field -->
        <app-input
          class="subject-input-field"
          [label]="'customLogin.requestAccessModal.step2.honeypotLabel' | cxTranslate"
          [placeholder]="'customLogin.requestAccessModal.step2.honeypotPlaceholder' | cxTranslate"
          name="subject"
          appInputForbiddenValidator
          [formGroupName]="formGroupName"
        ></app-input>

      </div>

      <div class="modal-submit-button">

        <app-button
          [label]=" 'common.send' | cxTranslate "
          buttonSize="SMALL"
          (activateElement)="send($event)"
          colorTheme="PRIMARY"
          [disabled]="invalid || (loading$ | async)"
          [showActivity]="(loading$ | async)"
          flex
        ></app-button>

        <div class="after-sent-text">
          {{ 'customLogin.requestAccessModal.step2.afterSentText' | cxTranslate }}
        </div>

      </div>

    }

  </ng-template>

  <!-- No Footer modal slot means that there will be footer element in the modal itself -->
  <!-- <ng-template modalSlot="footer"></ng-template> -->

</app-kurz-modal-wrapper>
