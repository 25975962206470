import { Injectable, inject } from '@angular/core';
import { AuthService, GlobalMessageEntities, GlobalMessageService, TranslationService, WindowRef, } from '@spartacus/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CookieBannerAndGtmService } from '../../services/cookie-banner-and-gtm.service';

export const LOGIN_MESSAGES_FE_KEY ='customLogin.messages.';

/**
 * To ensure the accurate translation of global messages,
 * including those originating from the BE and still unknown,
 * it is important to populate this array with the keys of
 * the custom translations implemented on the FE. This will apply
 * either the custom FE translation keys or the default ones that come
 * from the BE.
 */
export const FE_TRANSLATED_MESSAGES: string[] = [
  'httpHandlers.badRequestPleaseLoginAgain',
  'httpHandlers.disabledLogin',
  'httpHandlers.internalServerError',
  'authMessages.signedOutSuccessfully',
  'httpHandlers.sessionExpired',
];

/**
 * Override of the original Spartacus LoginFormComponentService
 * to achieve the expected Kurz notification behaviour on the login page
 * according to the clickdummy.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomLoginFormComponentService extends LoginFormComponentService {

  private usercentricsAndGtmService = inject(CookieBannerAndGtmService);
  private translationService = inject(TranslationService);

  public messages$: Observable<any>;

  loginFormId = '';

  constructor(auth: AuthService, globalMessage: GlobalMessageService, winRef: WindowRef) {
    super(auth, globalMessage, winRef);
    this.messages$ = this.globalMessage
      .get()
      .pipe(map((messages) => this.mapMessageKeysToFE(messages)));
  }

 /**
 * Maps message keys to Frontend custom key if existent.
 *
 * @param {GlobalMessageEntities} messages - The global message entities to be mapped.
 * @returns {GlobalMessageEntities | null} The mapped global message entities with FE keys, or null if input is undefined.
 */
  protected mapMessageKeysToFE( messages?: GlobalMessageEntities): GlobalMessageEntities | null {
    if (!messages) {
      return null;
    }

    //This ugly step is needed since the entities are readonly
    messages = { ...messages };

    Object.entries(messages).forEach(([messageType, translatables]) => {
      if (Array.isArray(translatables)) {
        messages[messageType] = translatables.map((translatable) => ({
          ...translatable,
          key: FE_TRANSLATED_MESSAGES.includes(translatable.key.toString())
            ? LOGIN_MESSAGES_FE_KEY + translatable.key
            : translatable.key,
        }));
      }
    });

    return messages;
  }

  protected override onSuccess(isLoggedIn: boolean): void {
    super.onSuccess(isLoggedIn);

    if (isLoggedIn) {
      this.translationService.translate('customLogin.formular.loginButtonLabel').pipe(filter(tr => !!tr), take(1)).subscribe(tr => {
        this.usercentricsAndGtmService.addLoginEvent(tr, this.loginFormId);
      });
    }
  }
}
