import { Component, Input } from '@angular/core';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { CustomLoginFormComponentService } from './custom-login-form-component.service';
import { KurzRequestAccessModalComponent } from '../../kurz-components/shared/kurz-request-access-modal/kurz-request-access-modal.component';
import { UtilModalService } from '@util/services/util-modal.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalMessageType } from '@spartacus/core';
import { coerceBoolean } from '@util/functions/objects';

@Component({
  selector: 'app-custom-login-form',
  templateUrl: './custom-login-form.component.html',
  styleUrls: ['./custom-login-form.component.scss']
})
export class CustomLoginFormComponent extends LoginFormComponent {

  static uniqueIdCounter = 0;

  uniqueLoginFormId = 'login-form-' + CustomLoginFormComponent.uniqueIdCounter++;

  messages$: Observable<any> = this.service.messages$;
  messageType: typeof GlobalMessageType = GlobalMessageType;

  hideCountrySelector$ = new BehaviorSubject<boolean>(false);
  hideRequestAccess$ = new BehaviorSubject<boolean>(false);

  @Input()
  set hideCountrySelector(value: boolean) {
    this.hideCountrySelector$.next(coerceBoolean(value));
  }

  @Input()
  set hideRequestAccess(value: boolean) {
    this.hideRequestAccess$.next(coerceBoolean(value));
  }

  constructor(protected override service: CustomLoginFormComponentService, private utilModalService: UtilModalService) {
    super(service);
  }

  openRequestAccessModal() {
    this.utilModalService.openCustomModal(KurzRequestAccessModalComponent, null, {
      closableByEscape: true,
      closeableWithBackdraftClick: false,
      size: 'md'
    });
  }

  override onSubmit(): void {
    this.service.loginFormId = this.uniqueLoginFormId;
    super.onSubmit();
  }
}
