<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<ng-container *ngIf="(hideCountrySelector$ | async) === false">
  <app-kurz-country-selector></app-kurz-country-selector>
</ng-container>

<form (ngSubmit)="onSubmit()" [attr.id]="uniqueLoginFormId" [formGroup]="form" class="login-formular">

  <h1>{{ 'customLogin.title' | cxTranslate }}</h1>

  <ng-container *ngIf="messages$ | async as messages">
    <div class="login-notification-container error-notification" *ngFor="let message of messages[messageType.MSG_TYPE_ERROR]" role="alert">
      <app-icon
        [iconType]="'WARNING'"
        iconSize="EXTRA_LARGE"
      ></app-icon>
      <p>{{ message.key | cxTranslate }}</p>
    </div>
    <div class="login-notification-container" *ngFor="let message of messages[messageType.MSG_TYPE_CONFIRMATION]" role="alert">
      <app-icon
        [iconType]="'SUCCESS'"
        iconSize="EXTRA_LARGE"
      ></app-icon>
      <p>{{ message.key | cxTranslate }}</p>
    </div>
    <div class="login-notification-container" *ngFor="let message of messages[messageType.MSG_TYPE_INFO]" role="alert">
      <app-icon
        [iconType]="'INFO'"
        iconSize="EXTRA_LARGE"
      ></app-icon>
      <p>{{ message.key | cxTranslate }}</p>
    </div>
    <div class="login-notification-container" *ngFor="let message of messages[messageType.MSG_TYPE_WARNING]" role="alert">
      <app-icon
        [iconType]="'INFO'"
        iconSize="EXTRA_LARGE"
      ></app-icon>
      <p>{{ message.key | cxTranslate }}</p>
    </div>
    <div class="login-notification-container" *ngFor="let message of messages[messageType.MSG_TYPE_ASSISTIVE]" role="alert">
      <app-icon
        [iconType]="'HELP'"
        iconSize="EXTRA_LARGE"
      ></app-icon>
      <p>{{ message.key | cxTranslate }}</p>
    </div>
  </ng-container>

  <div class="passwort-hint-container">
  </div>

  <label>
    <span class="label-content">{{ 'customLogin.formular.emailLabel' | cxTranslate }}</span>
    <input
      aria-required="true"
      type="email"
      class="form-control"
      formControlName="userId"
      placeholder="{{ 'customLogin.formular.emailPlaceholder' | cxTranslate }}"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('userId')"
      [prefix]="'customLogin.messages'"
    ></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'customLogin.formular.passwordLabel' | cxTranslate }}</span>
    <input
      aria-required="true"
      type="password"
      class="form-control"
      placeholder="{{ 'customLogin.formular.passwordPlaceholder' | cxTranslate }}"
      formControlName="password"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('password')"
      [prefix]="'customLogin.messages'"
    ></cx-form-errors>
  </label>

  <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"  class="forgot-password-link"> {{ 'customLogin.formular.forgotPasswordLabel' | cxTranslate }} </a>

  <app-button
    [label]="'customLogin.formular.loginButtonLabel' | cxTranslate"
    [disabled]="form.invalid"
    [colorTheme]="['PRIMARY', 'BASE', {}]"
    size="MEDIUM"
  ></app-button>
</form>

<ng-container *ngIf="(hideRequestAccess$ | async) === false">

  <div class="account-request-container">
    <h4>{{ 'customLogin.registerRequest.title' | cxTranslate }}</h4>
    <p>{{ 'customLogin.registerRequest.message' | cxTranslate }}</p>

    <app-button
      [label]="'customLogin.registerRequest.buttonLabel' | cxTranslate "
      [colorTheme]="['SECONDARY', 'WHITE']"
      [buttonSize]="'MEDIUM'"
      (activateElement)="openRequestAccessModal()"
    ></app-button>
  </div>

</ng-container>
